import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LANGUAGE } from '../constants/settings'
import bengali from './bn.json'
import english from './en.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: english
    },
    bn: {
      translation: bengali
    }
  },
  lng: LANGUAGE.ENGLISH,
  fallbackLng: LANGUAGE.ENGLISH,
  interpolation: {
    escapeValue: false
  }
})

export default i18n
