import { Drawer } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import Container from "../../components/Container";
import { IDS } from "../../constants/settings";
import HeaderContent from "../../content/HeaderContent.json";
import scrollTo from "../../utilities/scrollTo";
import {
  Burger,
  CloseOutline,
  CustomNavLinkSmall,
  HeaderSection,
  Label,
  LogoContainer,
  Menu,
  NotHidden,
  Outline,
  Span,
} from "./styles";

import "./Header.scss";

interface IMenuItem {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
}

const MenuItem: React.FC<IMenuItem> = ({ setVisibility, style = {} }) => {
  const { t } = useTranslation();

  return (
    <div style={style}>
      {HeaderContent.navigations.map((item, index) => {
        return (
          <CustomNavLinkSmall
            onClick={() => scrollTo(item?.scrollTo, () => setVisibility(false))}
            key={`header-navlink-${index}`}
          >
            <Span>{t(item?.title)}</Span>
          </CustomNavLinkSmall>
        );
      })}
      <CustomNavLinkSmall
        style={{ width: "180px" }}
        onClick={() => scrollTo(IDS.CONTACT, () => setVisibility(false))}
      >
        <Span>
          <Button>{t("Contact")}</Button>
        </Span>
      </CustomNavLinkSmall>
    </div>
  );
};

const Header = () => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  return (
    <HeaderSection>
      <Container>
        <div className="header-container">
          <div>
            <LogoContainer to="/" aria-label="homepage">
              <img
                style={{
                  marginRight: 12,
                }}
                src="/img/icons/logo192.png"
                width="64px"
                height="64px"
                alt={HeaderContent.title}
              />
              <p style={{ fontWeight: 800 }}>{HeaderContent.title}</p>
            </LogoContainer>
          </div>
          <NotHidden>
            <MenuItem setVisibility={setVisibility} />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </div>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <div>
            <Label onClick={onClose}>
              <div>
                <Menu>Menu</Menu>
              </div>
              <div>
                <CloseOutline />
              </div>
            </Label>
          </div>
          <MenuItem
            setVisibility={setVisibility}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default Header;
