const theme = {
  palette: {
    primary: {
      main: "#2e186a",
    },
    secondary: {
      main: "rgb(255, 130, 92)",
    },
    tertiary: {
      main: "#18216d",
    },
    background: {
      paper: "#fff",
    },
    action: {
      hover: "#edf3f5",
      selected: "#CDD1D4",
    },
    text: {
      primary: "#000",
    },
  },
} as any;

export default theme;
