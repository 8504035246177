export enum LANGUAGE {
  ENGLISH = "en",
  BENGALI = "bn",
}

export enum THEME {
  LIGHT = "light",
  DARK = "dark",
}

export enum IDS {
  INTRO = "intro",
  ABOUT = "about",
  MISSION = "mission",
  ADDRESS = "address",
  CONTACT = "contact",
  JOIN_US = "join-us",
  TECHNOLOGY_AND_FRAMEWORK = 'techonology-and-framework'
}
