import { StyledContainer } from "../../components/Container/styles";
import { SvgIcon } from "../../components/SvgIcon";
import FooterContent from "../../content/FooterContent.json";
import { Language, LogoContainer, NavLink, Para } from "./styles";
import "./Footer.scss";

interface SocialLinkProps {
  href: string;
  src: string;
}

const SocialLink = ({ href, src }: SocialLinkProps) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      key={src}
      aria-label={src}
      style={{
        margin: "16px",
      }}
    >
      <SvgIcon src={src} width="25px" height="25px" />
    </a>
  );
};

const Footer = () => {
  return (
    <>
      <div
        className="pre-last-section"
        style={{
          background: "rgb(241, 242, 243)",
        }}
      >
        <StyledContainer>
          <div style={{ padding: 16 }}>
            <div
              style={{
                textTransform: "uppercase",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ color: "grey", margin: 0 }}>In&nbsp;</p>
              <p style={{ margin: 0, color: "black" }}>Association&nbsp;</p>
              <p style={{ color: "grey", margin: 0 }}>With</p>
            </div>
            <div>
              <img
                style={{
                  marginRight: 16,
                  marginTop: 16,
                }}
                src="/img/icons/basis_logo.png"
                alt={FooterContent.title}
              />
            </div>
          </div>
          <div className="addresses">
            {FooterContent.addresses.map((item) => {
              return (
                <div key={item?.title}>
                  <Language>{item?.title}</Language>
                  <Para>
                    <a href={`mailto:${item?.email}`}>{item?.email}</a>
                  </Para>
                  <Para>
                    <a href={`tel:${item?.phone}`}>{item?.phone}</a>
                  </Para>
                  <Para>{item?.address}</Para>
                </div>
              );
            })}
          </div>
        </StyledContainer>
      </div>
      <div
        style={{
          background: "rgb(241, 242, 243)",
          padding: "16px",
        }}
      >
        <StyledContainer>
          <div className="last-section">
            <div>
              <NavLink to="/">
                <LogoContainer>
                  <img
                    style={{
                      marginRight: 12,
                    }}
                    src="/img/icons/logo192.png"
                    width="64px"
                    height="64px"
                    alt={FooterContent.title}
                  />
                  <p style={{ fontWeight: 800 }}>{FooterContent.title}</p>
                </LogoContainer>
              </NavLink>
            </div>
            <div className="socials">
              {FooterContent.socials.map((item) => {
                return (
                  <SocialLink
                    href={item?.link}
                    src={item?.icon}
                    key={item?.icon}
                  />
                );
              })}
            </div>
          </div>
        </StyledContainer>
      </div>
    </>
  );
};

export default Footer;
