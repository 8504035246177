import styled from "styled-components";
import theme from "../../constants/theme";

export const StyledContainer = styled("div")<any>`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  border-top: ${(p) =>
    p.border ? `1px solid ${theme.palette.action.selected}` : ""};

  @media screen and (max-width: 1200px) {
    padding: 0;
  }
`;
