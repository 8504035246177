import { lazy, Suspense } from "react";
import { Switch, Route, Router } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import routes from "./config";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const Routes = () => {
  return (
    <Suspense fallback={null}>
      <Router history={history}>
        <Header />
        <Switch>
          {routes.map((routeItem) => {
            return (
              <Route
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                component={lazy(
                  () => import(`../pages/${routeItem.component}`)
                )}
              />
            );
          })}
        </Switch>
        <Footer />
      </Router>
    </Suspense>
  );
};

export default Routes;
